html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
  background-color: var(--color-grayscale-xdark);
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	color: #444;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

body {
	max-width: 100%;
	overflow-x: hidden;
}

* {
	box-sizing: border-box;
	outline-color: var(--color-primary-default);
  font-family: inherit;
}

*:focus-visible {
	outline-style: double;
}

#preact_root {
	height: 100%;
}

ul {
	list-style: none;
}

:root {
  --color-answer-a: #F25559;
  --color-answer-b: #00ABEB;
  --color-answer-c: #37B048;
  --color-answer-d: #FFA400;

  --color-answer-transparent-a: rgba(242, 85, 89, 0.35);
  --color-answer-transparent-b: rgba(0, 171, 235, 0.35);
  --color-answer-transparent-c: rgba(55, 176, 72, 0.35);
  --color-answer-transparent-d: rgba(248, 179, 27, 0.35);

  --color-answer-background-a: #272222;
	--color-answer-background-b: #292F34;
	--color-answer-background-c: #202721;
	--color-answer-background-d: #2A2824;

	--color-white: #FAFAFA;
	--color-grayscale-xxxlight: #F2F2F2;
	--color-grayscale-xxlight: #D6D6D6;
	--color-grayscale-xlight: #A3A3A3;
	--color-grayscale-light: #929292;
	--color-grayscale-default: #5C6162;
	--color-grayscale-dark: #4B5253;
	--color-grayscale-xdark: #424849;
	--color-grayscale-xxdark: #3D4242;
	--color-grayscale-xxxdark: #313434;
	--color-black: #222;

	--color-primary-default: #FFA400;
	--color-primary-hover: #FFB903;

  --font-size-xxxlarge: 2.5rem;
  --font-size-xxlarge: 1.6rem;
  --font-size-xlarge: 1.3rem;
  --font-size-large: 1.1rem;
  --font-size-default: 1rem;
  --font-size-small: 0.9rem;
  --font-size-xsmall: 0.75rem;
}

h1, h2, h3, h4, h5, h6, button, input, textarea {
	color: var(--color-white);
}

h1, h2, h3, h4, h5, h6, p, span, button, input, textarea {
	margin: 0;
}

p {
	color: var(--color-grayscale-light);
}

a, button {
	border: none;
	cursor: pointer
}

ul {
	padding: 0;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex-column {
	display: flex;
	flex-direction: column;
}